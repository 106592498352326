<template>
  <MainHeader v-if="windowWidth > 420">
    <div class="header-row">
      <div class="title-section">
        <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
        <div class="title-container">
          <div class="title">
            Pipeline Value
            {{ formatPipelineValue(activePipeline.projected_value) }}
          </div>
          <div class="current-date">{{ getPipelineDate() }}</div>
          <div class="reload" :class="{ spin: isPipelineValueRecalculating }">
            <img
              src="../../../../assets/icons/reload.svg"
              alt="reload"
              @click="recalculatePipeline"
            />
          </div>
        </div>
        <!-- <div class="vertical-divider"></div> -->
      </div>
      <UserInfoHeaderSection :username="currentUser.full_name" />
    </div>
    <div class="header-row">
      <div class="left-side-actions">
        <UndoRedoBtns
          :isUndoStackActive="isUndoStackActive"
          :isRedoStackActive="isRedoStackActive"
          @undo="Undo()"
          @redo="Redo()"
        />
        <div class="goal-values">
          <PipelineGoalField
            :value="activePipeline.goal_one"
            :placeholder="'Monthly Revenue Goal'"
            :isSubscriptionActive="isSubscriptionActive"
            @pipelineGoalUpdated="
              handlePipelineGoalsUpdate({ goal_one: $event })
            "
          />
          <PipelineGoalField
            :value="activePipeline.goal_two"
            :placeholder="'Confidence Level %'"
            :isSubscriptionActive="isSubscriptionActive"
            @pipelineGoalUpdated="
              handlePipelineGoalsUpdate({ goal_two: $event })
            "
          />
          <PipelineGoalField
            :value="activePipeline.goal_three"
            :placeholder="'Non-revenue Goal'"
            :isSubscriptionActive="isSubscriptionActive"
            @pipelineGoalUpdated="
              handlePipelineGoalsUpdate({ goal_three: $event })
            "
          />
        </div>
        <div class="more-actions-container">
          <MoreActionsBtn
            :width="'44px'"
            :height="'46px'"
            :isActive="isActionMenuShown"
            @click="openHeaderActionMenu"
          />
          <ActionMenu
            :width="'280px'"
            :height="'50px'"
            :top="'0px'"
            :right="'55px'"
            :isActionMenuShown="isActionMenuShown"
            @closeActionMenu="closeHeaderActionMenu"
          >
            <ActionMenuItem
              v-for="(action, index) in Object.values(topMenuActions)"
              :key="index"
              :text="action"
              @actionMenuItemClick="handleActionMenuClick(action)"
            />
          </ActionMenu>
        </div>

        <ReportsButton
          :width="'44px'"
          :height="'46px'"
          :class="{ 'inactive-report': !isSubscriptionActive }"
          @click="getReportsForActivePipeline"
        />
      </div>
      <div class="right-side-actions">
        <ActionButton
          :width="'165px'"
          :height="'46px'"
          :text="'+ Add new client'"
          :class="{
            'inactive-btn': !isSubscriptionActive,
            'add-customer-btn': true,
          }"
          @click="showModal"
        />
        <div class="buttons-divider"></div>
        <ActionButton
          :width="'116px'"
          :height="'46px'"
          :text="'Import'"
          :class="{ 'import-btn': true }"
          @click="isImportModalShown = true"
        />
      </div>
      <ClientsActionModal
        :isModalShown="isModalShown"
        :isModalLoading="isModalLoading"
        :modalError="modalError"
        :newClientName="newClientName"
        :newClientCompany="newClientCompany"
        :newClientPipelineStatus="newClientPipelineStatus"
        :newDealValue="newDealValue"
        :pipelineStatuses="pipelineStatuses"
        @hideModal="hideModal"
        @setNewClientName="setClientName"
        @setNewClientCompany="setClientCompany"
        @setNewClientPipelineStatus="setNewClientPipelineStatus"
        @setNewDealValue="setNewDealValue"
        @createClient="handleCreateClientAndDeal"
      />
    </div>
  </MainHeader>
  <MobileHeaderRow
    v-if="windowWidth <= 420"
    :isSubscriptionActive="isSubscriptionActive"
    :selectedRows="selectedRows"
    :selectedSubjects="selectedSubjects"
    @openSidebar="$emit('openSidebar')"
    @showImportModal="isImportModalShown = true"
  />
  <ImportModal
    :isModalShown="isImportModalShown"
    :isModalLoading="isModalLoading"
    :importedFileName="csvFile?.name"
    :modalMessage="modalMessage"
    :modalError="modalError"
    @setCsvFile="setCsvFile"
    @importItems="submitClientsImport"
    @hideModal="closeImportModal"
  />
  <MainCard>
    <MainCardHeader>
      <div class="card-header-row">
        <div class="card-title">
          Clients: In Pipeline
          <InfoBox
            text="Customers with recurring business that you expect to close in the near term. 90% of the value is added to your pipeline."
          />
        </div>
        <SearchField
          @search="handleSearch($event)"
          @cancelSearch="handleSearch($event)"
        />
      </div>
      <CardTableHeader
        :isCheckboxShown="true"
        :isCompanyShown="true"
        :isValueShown="true"
        :selectedRows="selectedRows"
        :isSelectAllCheckbox="isSelectAllCheckbox"
        :isNoteShown="true"
        :order="order"
        @checkboxSelect="selectAllRows"
        @removeSelection="removeRowsSelection"
        @setOrder="setOrder"
      />
    </MainCardHeader>
    <DividerLine />
    <div
      class="deals-list"
      v-if="currentPage.length && !errorMessage && !isLoading"
    >
      <div class="action-btn-absolute-wrapper">
        <div
          v-for="page in currentPage"
          :key="currentPage"
          class="action-btn-container"
        >
          <MoreActionsBtn
            :width="'32px'"
            :height="'32px'"
            :isActive="openedMenu === page.id"
            @click="switchActionMenuState(page.id)"
          />
          <ActionMenu
            :width="'282px'"
            :height="'147px'"
            :top="'40px'"
            :right="'20px'"
            :isActionMenuShown="openedMenu === page.id"
            @closeActionMenu="closeActionMenu"
          >
            <ActionMenuItem
              v-for="(action, index) in menuActions"
              :key="index"
              :text="action"
              @actionMenuItemClick="
                handleActionMenuItemClick(action, page.id, page.subject)
              "
            />
          </ActionMenu>
        </div>
      </div>
      <div style="overflow-x: scroll; padding-bottom: 4px">
        <draggable
          v-model="currentPage"
          handle=".drag-handle"
          ghost-class="ghost"
          chosen-class="chosen-item"
          :disabled="order !== '' || isDragDisabled"
          @end="handleDragEnd"
          item-key="id"
        >
          <template #item="{ element }">
            <PipelineCardRow
              :key="element.id"
              :id="element.id"
              :subjectID="element.subject"
              :note="element.subject_note"
              :order="order"
              :isCompanyEditable="true"
              :isNameEditable="true"
              :name="element.subject_name"
              :company="element.subject_company"
              :dealValue="element.value"
              :selectedRows="selectedRows"
              :menuActions="Object.values(menuActions)"
              :actionMenuWidth="'282px'"
              :actionMenuHeight="'147px'"
              :actionMenuTop="'45px'"
              :isSubscriptionActive="isSubscriptionActive"
              :isOutOfReportRow="isItemOutOfReport(element.id)"
              @updateName="
                handleDealSubjectUpdate({ subject_name: $event }, element)
              "
              @updateCompany="
                handleDealSubjectUpdate({ subject_company: $event }, element)
              "
              @changeDealClosedState="
                handleDealStatusChange(element.value, element.id)
              "
              @updateDealValue="handleDealValueUpdate($event, element.id)"
              @checkboxSelect="selectOneRow(element.id, element.subject)"
              @removeSelection="
                removeOneRowSelection(element.id, element.subject)
              "
              @actionMenuItemClick="
                handleActionMenuClick($event, element.id, element.subject)
              "
            />
          </template>
        </draggable>
      </div>
    </div>
    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <Spinner v-if="isLoading" />
    <DividerLine />
    <CardFooter
      :currentPageNumber="currentPageNumber"
      :totalPages="toPages"
      :currentItemsCount="`${fromItems}-${toItems}`"
      :totalItemsCount="totalItemsCount"
      :RowsPerPage="RowsPerPage"
      @setCurrentPerPage="setCurrentPerPage($event)"
      @showPrevPage="getPrevPage"
      @showNextPage="getNextPage"
    />
  </MainCard>
  <NotInPipelineClientsList
    :selectedRows="selectedSubjects"
    :shouldUpdateClientsList="shouldUpdateClientsList"
    @selectOneSubject="selectOneSubject"
    @removeOneSubjectSelection="removeOneSubjectSelection"
    @selectAllSubjects="selectAllSubjects"
    @removeAllSubjectsSelection="removeAllSubjectsSelection"
    @clientsListWasUpdated="shouldUpdateClientsList = false"
    @updateClientDealsList="updateClientDealsList"
    @moveClientToProspect="showClientToPropsectModal"
  />
  <ClientToProspectModal
    :isModalShown="isClientToProspectModalShown"
    :isModalLoading="isModalLoading"
    :modalError="modalError"
    :newProspectStatus="newProspectStatus"
    :newDealValue="newDealValue"
    @hideModal="hideClientToProspectModal"
    @setNewProspectStatus="setNewProspectStatus"
    @setNewDealValue="setNewDealValue"
    @moveToProspects="moveClientToProspects"
  />
  <div class="spacer"></div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import draggable from 'vuedraggable'

import MainHeader from '../../../common/header/MainHeader.vue'
import UndoRedoBtns from '../../../common/header/UndoRedoBtns.vue'
import MoreActionsBtn from '../../../common/MoreActionsBtn.vue'
import UserInfoHeaderSection from '../../../common/header/UserInfoHeaderSection.vue'
import ActionButton from '../../../common/ActionButton.vue'
import ClientsActionModal from '../../../common/ClientsActionModal.vue'
import MainCard from '../../../common/mainContent/MainCard.vue'
import CardTableHeader from '../../../common/mainContent/CardTableHeader.vue'
import DividerLine from '../../../common/DividerLine.vue'
import MainCardHeader from '../../../common/mainContent/MainCardHeader.vue'
import SearchField from '../../../common/header/SearchField.vue'
import InfoBox from '../../../common/InfoBox.vue'
import Spinner from '../../../common/Spinner.vue'
import CardFooter from '../../../common/mainContent/CardFooter.vue'
import ActionMenu from '../../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../../common/actionMenu/ActionMenuItem.vue'
import PipelineGoalField from '../../../common/mainContent/PipelineGoalField.vue'
import PipelineCardRow from '../PipelineCardRow.vue'
import MobileHeaderRow from '../../../common/header/MobileHeaderRow.vue'
import ReportsButton from '../../../common/ReportsButton.vue'
import NotInPipelineClientsList from './NotInPipelineClientsList.vue'
import ClientToProspectModal from './ClientToProspectModal.vue'
import ImportModal from '../../../common/ImportModal.vue'

import { getPipelineDate } from '../../../../utils/helpers'
import {
  DealType,
  UserSubscriptionStatus,
  ProspectStatus,
} from '../../../../utils/constants'
import { recalculatePipelineValue } from '../../../../api/pipelinesApi'

export default {
  components: {
    draggable,
    MainHeader,
    UndoRedoBtns,
    MoreActionsBtn,
    UserInfoHeaderSection,
    ActionButton,
    ClientsActionModal,
    MainCard,
    DividerLine,
    CardTableHeader,
    SearchField,
    MainCardHeader,
    Spinner,
    CardFooter,
    ActionMenu,
    ActionMenuItem,
    PipelineGoalField,
    PipelineCardRow,
    MobileHeaderRow,
    ReportsButton,
    NotInPipelineClientsList,
    ClientToProspectModal,
    ImportModal,
    InfoBox,
  },
  emits: ['openSidebar'],
  data() {
    return {
      windowWidth: window.innerWidth,
      isLoading: true,
      isPipelineValueRecalculating: false,
      pageSize: 'All',
      currentPageNumber: 1,
      searchTerm: '',
      order: '',
      isDragDisabled: false,
      isActionMenuShown: false,
      isModalShown: false,
      isModalLoading: false,
      modalError: '',
      newClientName: '',
      newClientCompany: '',
      newClientPipelineStatus: 'In Pipeline',
      newDealValue: null,
      pipelineStatuses: {
        'Not in Pipeline': 0,
        'In Pipeline': 1,
      },
      selectedRows: [],
      selectedSubjects: [],
      actionActive: false,
      errorMessage: '',
      isSelectAllCheckbox: false,
      topMenuActions: {
        ARCHIVE: 'Archive',
      },
      menuActions: {
        REMOVE_FROM_PIPELINE: 'Remove from Pipeline',
        MOVE_TO_PROSPECTS: 'Move to Prospects',
        ARCHIVE: 'Archive',
      },
      dealsType: DealType,
      activeSubscriptionStatuses: [
        UserSubscriptionStatus.Granted,
        UserSubscriptionStatus.Active,
        UserSubscriptionStatus['Granted Until'],
        UserSubscriptionStatus.Canceled,
      ],
      RowsPerPage: ['All', 10, 20, 50],
      shouldUpdateClientsList: false,
      isClientToProspectModalShown: false,
      clientToMoveId: null,
      newProspectStatus: 'Target',
      prospectStatus: ProspectStatus,
      isClientDealExist: false,
      isImportModalShown: false,
      csvFile: null,
      modalMessage: '',
      openedMenu: '',
    }
  },
  async created() {
    await this.getInitialData()
  },
  computed: {
    ...mapState([
      'currentUser',
      'clientDeals',
      'activePipeline',
      'isUndoStack',
      'isRedoStack',
    ]),
    isSubscriptionActive() {
      return this.activeSubscriptionStatuses.includes(
        this.currentUser.subscription.status
      )
    },
    clientDealsList() {
      return this.clientDeals.clientDealsList
    },
    currentPage: {
      get() {
        if (this.pageSize === 'All') return this.clientDealsList

        const start =
          this.currentPageNumber === 1
            ? 0
            : this.currentPageNumber * this.pageSize - this.pageSize

        const stop =
          this.currentPageNumber === 1
            ? this.pageSize
            : this.currentPageNumber * this.pageSize

        const page = this.clientDealsList.slice(start, stop)

        return page
      },
      set(value) {},
    },
    totalItemsCount() {
      return this.clientDeals.clientDealsCount
    },
    isUndoStackActive() {
      return this.isUndoStack
    },
    isRedoStackActive() {
      return this.isRedoStack
    },
    lastId() {
      return this.currentPage.length ? this.currentPage.pop().id : 0
    },
    pageItemsBase() {
      return this.pageSize * this.currentPageNumber
    },
    fromItems() {
      if (this.pageSize === 'All') return 1

      return this.totalItemsCount === 0
        ? 0
        : this.pageItemsBase + 1 - this.pageSize
    },
    toPages() {
      if (this.pageSize === 'All') return 1
      if (this.totalItemsCount < this.pageSize) return 1

      return Math.ceil(this.totalItemsCount / this.pageSize)
    },
    toItems() {
      if (this.pageSize === 'All') return this.clientDealsList.length

      return this.pageItemsBase < this.totalItemsCount
        ? this.pageItemsBase
        : this.totalItemsCount
    },
  },
  watch: {
    modalError(newValue, oldValue) {
      if (newValue) {
        setTimeout(() => {
          this.modalError = ''
        }, 3000)
      }
    },
    selectedRows(newValue, oldValue) {
      if (newValue.length >= 3) {
        this.actionActive = true
      } else {
        this.actionActive = false
      }
    },
    async order() {
      this.isLoading = true
      this.currentPageNumber = 1

      await this.clearClientDeals()
      await this.getFirstPage()
    },
  },
  methods: {
    ...mapActions([
      'getActivePipeline',
      'updateDealsOrder',
      'updatePipelineGoals',
      'getNextClientDealsPage',
      'createClientAndDeal',
      'updateClientDealValue',
      'updateClientDealSubject',
      'openClientDeal',
      'closeClientDeal',
      'removeClientDeals',
      'clearClientDeals',
      'getMonthReportHtml',
      'moveClientsToProspects',
      'moveSingleClientToProspects',
      'moveClientsPipelineToProspects',
      'searchClientDeals',
      'handelUndoRedoClick',
      'archiveClients',
      'importCustomers',
    ]),
    getPipelineDate,
    switchActionMenuState(id) {
      if (!this.isSubscriptionActive) return
      if (this.openedMenu === id) this.openedMenu = null
      else this.openedMenu = id
    },
    closeActionMenu() {
      this.openedMenu = null
    },
    handleActionMenuItemClick(action, id, subjectId) {
      this.handleActionMenuClick(action, id, subjectId)
    },
    async recalculatePipeline() {
      try {
        this.isPipelineValueRecalculating = true

        const { pipeline_projected_value } = await recalculatePipelineValue()
        this.activePipeline.projected_value = pipeline_projected_value

        setTimeout(() => (this.isPipelineValueRecalculating = false), 500)
      } catch (error) {
        this.handleGeneralError(error)
      }
    },
    handleGeneralError(error) {
      this.errorMessage = 'Something went wrong, please try again'
    },
    async getInitialData() {
      try {
        await this.getActivePipeline()
        await this.getFirstPage()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    async setCurrentPerPage(rowperPage) {
      this.currentPageNumber = 1
      this.pageSize = rowperPage
      await this.clearClientDeals()
      await this.getFirstPage()
    },
    async Undo() {
      this.currentPageNumber = 1
      await this.handelUndoRedoClick('undoStack')
      await this.clearClientDeals()
      await this.getFirstPage()
    },
    async Redo() {
      this.currentPageNumber = 1
      await this.handelUndoRedoClick('redoStack')
      await this.clearClientDeals()
      await this.getFirstPage()
    },
    formatPipelineValue(value) {
      if (value && value > 0) {
        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '$0'
    },
    async handleSearch(searchValue) {
      this.isLoading = true
      this.searchTerm = searchValue
      this.currentPageNumber = 1

      const payload = {
        order: this.order,
        searchTerm: searchValue,
        pageSize: this.pageSize,
        pageNumber: this.currentPageNumber,
        dealsType: this.dealsType.ClientDeal,
      }

      try {
        await this.searchClientDeals(payload)
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    async handlePipelineGoalsUpdate(goalUpdate) {
      const goals = {
        goal_one: this.activePipeline.goal_one,
        goal_two: this.activePipeline.goal_two,
        goal_three: this.activePipeline.goal_three,
      }
      const payload = { ...goals, ...goalUpdate }

      await this.updatePipelineGoals(payload)
    },
    async getFirstPage() {
      try {
        const payload = {
          order: this.order,
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          dealsType: this.dealsType.ClientDeal,
        }

        await this.getNextClientDealsPage(payload)
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    setOrder(evt) {
      if (this.isLoading) return
      this.order = evt
    },
    async handleDragEnd(evt) {
      this.isDragDisabled = true

      const { oldIndex, newIndex } = evt

      if (oldIndex === newIndex) return

      const elementId = this.currentPage[oldIndex].id

      try {
        await this.updateDealsOrder({
          elementId,
          oldLocalIndex: oldIndex,
          newLocalIndex: newIndex,
          dealsType: this.dealsType.ClientDeal,
        })
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isDragDisabled = false
      }
    },
    async getNextPage() {
      if (
        (this.currentPageNumber !== 0 &&
          this.toItems === this.totalItemsCount) ||
        this.isLoading
      )
        return

      this.currentPageNumber += 1
      this.selectedRows = []
      this.selectedSubjects = []

      if (this.currentPage.length) return

      this.isLoading = true

      try {
        const payload = {
          order: this.order,
          pageSize: this.pageSize,
          searchTerm: this.searchTerm,
          pageNumber: this.currentPageNumber,
          dealsType: this.dealsType.ClientDeal,
        }
        await this.getNextClientDealsPage(payload)
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    getReportsForActivePipeline() {
      if (!this.isSubscriptionActive) return
      this.getMonthReportHtml(this.activePipeline.id)
    },
    getPrevPage() {
      if (this.currentPageNumber === 1) return

      this.selectedRows = []
      this.selectedSubjects = []
      this.currentPageNumber -= 1
    },
    isItemOutOfReport(itemId) {
      const itemIndex = this.clientDealsList.findIndex((i) => i.id === itemId)

      if (itemIndex > 59) return true

      return false
    },
    setCsvFile(evt) {
      this.csvFile = evt.target.files[0]
    },
    closeImportModal() {
      this.isImportModalShown = false
      this.isModalLoading = false
      this.modalMessage = ''
      this.modalError = ''
      this.csvFile = null
    },
    async submitClientsImport() {
      this.modalError = ''
      this.modalMessage = ''
      this.isModalLoading = true

      try {
        const message = await this.importCustomers(this.csvFile)

        this.modalMessage = message
        this.shouldUpdateClientsList = true
        await this.updateClientDealsList()
      } catch (error) {
        this.modalError = 'Something went wrong. Please, try again'
      } finally {
        this.isModalLoading = false
        this.csvFile = null
      }
    },
    openHeaderActionMenu() {
      if (!this.isSubscriptionActive) return
      this.isActionMenuShown = true
      this.actionActive = false
    },
    closeHeaderActionMenu() {
      this.isActionMenuShown = false
    },
    showModal() {
      if (!this.isSubscriptionActive) return
      this.isModalShown = true
    },
    hideModal() {
      this.isModalShown = false
      this.newClientName = ''
      this.newClientCompany = ''
      this.newClientPipelineStatus = 'In Pipeline'
      this.newDealValue = null
    },
    setClientName(newClientName) {
      this.newClientName = newClientName
    },
    setClientCompany(newClientCompany) {
      this.newClientCompany = newClientCompany
    },
    setNewClientPipelineStatus(newPipelineStatus) {
      this.newClientPipelineStatus = newPipelineStatus

      if (newPipelineStatus === 'Not in Pipeline') {
        this.newDealValue = null
      }
    },
    setNewDealValue(newDealValue) {
      this.newDealValue = newDealValue
    },
    async handleCreateClientAndDeal() {
      if (!this.newClientName || !this.newClientCompany) {
        this.modalError = 'Client name and company are required'
        return
      }

      if (
        this.newClientPipelineStatus === 'In Pipeline' &&
        !Number(this.newDealValue)
      ) {
        this.modalError =
          'Deal value is required if you want to add client to the pipeline'
        return
      }

      const payload = {
        new_client: {
          name: this.newClientName,
          company: this.newClientCompany,
        },
        dealValue: this.newDealValue,
      }

      try {
        this.isModalLoading = true

        await this.createClientAndDeal(payload)

        this.order = ''
        this.isModalShown = false
      } catch (error) {
        console.log('==== error while creating deals: ', error)
        this.modalError = 'Something went wrong, please try again...'
      } finally {
        this.newClientName = ''
        this.newClientCompany = ''
        this.newDealValue = null
        this.isModalLoading = false
      }
    },
    showClientToPropsectModal(clientId, isClientDealExist = false) {
      if (!this.isSubscriptionActive) return

      this.clientToMoveId = clientId

      const existingDeal = this.clientDealsList.find(
        (i) => i.subject === clientId
      )

      if (existingDeal) {
        this.newDealValue = existingDeal.value
      }

      this.isClientDealExist = isClientDealExist
      this.isClientToProspectModalShown = true
    },
    hideClientToProspectModal() {
      this.isClientToProspectModalShown = false
      this.clientToMoveId = null
      this.newProspectStatus = 'Target'
      this.newDealValue = null
      this.modalError = ''
      this.isModalLoading = false
    },
    setNewProspectStatus(statusName) {
      this.newProspectStatus = statusName
    },
    async moveClientToProspects() {
      this.isModalLoading = true

      if (
        this.newProspectStatus !== 'Not in Pipeline' &&
        !Number(this.newDealValue)
      ) {
        this.modalError =
          'Deal value is required if you want to add prospect to the pipeline'
        this.isModalLoading = false

        return
      }

      const dealValueToSend =
        this.newProspectStatus === 'Not in Pipeline' ? 0 : this.newDealValue

      const payload = {
        client_id: this.clientToMoveId,
        deal_value: dealValueToSend,
        prospect_status: this.prospectStatus[this.newProspectStatus],
        isClientDealExist: this.isClientDealExist,
      }

      try {
        await this.moveSingleClientToProspects(payload)
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.hideClientToProspectModal()
      }
    },
    handleDealSubjectUpdate(dealSubjectUpdate, deal) {
      try {
        const updatedDeal = { ...deal, ...dealSubjectUpdate }
        this.updateClientDealSubject(updatedDeal)
      } catch (error) {
        this.handleGeneralError(error)
      }
    },
    selectAllRows() {
      const ids = this.currentPage.map((item) => item.id)
      const subject_id = this.currentPage.map((item) => item.subject)

      this.selectedSubjects = subject_id
      this.selectedRows = ids
    },
    removeRowsSelection() {
      this.selectedRows = []
      this.selectedSubjects = []
    },
    selectOneRow(rowId, subjectId) {
      this.selectedRows.push(rowId)
      this.selectedSubjects.push(subjectId)

      if (this.selectedRows.length >= 1) {
        this.actionActive = true
      }

      if (
        this.selectedRows.length === this.currentPage.length &&
        this.selectedSubjects.length === this.currentPage.length
      ) {
        this.isSelectAllCheckbox = true
      }
    },
    removeOneRowSelection(rowId, subjectId) {
      const rowIndex = this.selectedRows.indexOf(rowId)
      const subjectIndex = this.selectedSubjects.indexOf(subjectId)

      this.selectedRows.splice(rowIndex, 1)
      this.selectedSubjects.splice(subjectIndex, 1)

      if (this.selectedRows.length < 1) {
        this.actionActive = false
      }

      if (
        this.selectedRows.length < this.currentPage.length &&
        this.selectedSubjects.length < this.currentPage.length
      ) {
        this.isSelectAllCheckbox = false
      }
    },
    selectAllSubjects(subjectIds) {
      this.selectedSubjects = subjectIds

      if (this.selectedSubjects.length) {
        this.actionActive = true
      }
    },
    removeAllSubjectsSelection() {
      this.selectedSubjects = []

      if (!this.selectedRows.length && !this.selectedSubjects.length) {
        this.actionActive = false
      }
    },
    selectOneSubject(subjectId) {
      this.selectedSubjects.push(subjectId)
      this.actionActive = true
    },
    removeOneSubjectSelection(subjectId) {
      const subjectIndex = this.selectedSubjects.indexOf(subjectId)
      this.selectedSubjects.splice(subjectIndex, 1)

      if (!this.selectedRows.length && !this.selectedSubjects.length) {
        this.actionActive = false
      }
    },
    async handleDealValueUpdate(newDealValue, dealId) {
      const dealUpdate = { value: Number(newDealValue), id: dealId }
      await this.updateClientDealValue(dealUpdate)
    },
    async handleDealStatusChange(dealValue, dealId) {
      const payload = { id: dealId, value: Number(dealValue) }

      await this.closeClientDeal(payload)
    },
    async handleActionMenuClick(action, dealId = null, subjectId = null) {
      this.openedMenu = null
      this.isLoading = true

      const dealIds = dealId ? [dealId] : this.selectedRows
      const subjectIds = subjectId ? [subjectId] : this.selectedSubjects

      if (!dealIds.length && !subjectIds.length) {
        this.isLoading = false
        return
      }

      try {
        if (action === this.menuActions.REMOVE_FROM_PIPELINE) {
          await this.removeClientDeals(dealIds)

          this.shouldUpdateClientsList = true
        }

        if (action === this.menuActions.MOVE_TO_PROSPECTS) {
          if (subjectId) {
            this.showClientToPropsectModal(subjectId, true)
            return
          }

          const payload = {
            dealIds: dealIds,
            subjectIds: subjectIds,
          }

          await this.moveClientsPipelineToProspects(payload)
        }

        if (action === this.menuActions.ARCHIVE) {
          if (dealIds.length) {
            await this.removeClientDeals(dealIds)
          }

          await this.archiveClients(subjectIds)

          this.shouldUpdateClientsList = true
        }

        this.currentPageNumber = 1

        await this.clearClientDeals()

        const payload = {
          order: this.order,
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          dealsType: this.dealsType.ClientDeal,
        }

        await this.getNextClientDealsPage(payload)

        this.removeRowsSelection()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    async updateClientDealsList() {
      this.isLoading = true
      this.pageSize = 'All'
      this.currentPageNumber = 1
      this.searchTerm = ''
      this.order = ''

      await this.clearClientDeals()
      await this.getFirstPage()
    },
  },
  async beforeUnmount() {
    await this.clearClientDeals()
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
}

.inactive-report {
  background-image: url('../../../../assets/icons/inactive-report.svg');
}

.inactive-btn {
  background-color: #9c9ea6 !important;
  cursor: not-allowed;
}

.active-more-action-btn {
  background-color: $appActionColor;
  background-image: url('../../../../assets/icons/more-white-icon.svg');
}

.main-header {
  width: calc(100% - 260px);
  height: 180px;
  min-height: 160px;
  padding: 0 60px;
  padding-bottom: 20px;
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  background: #e5e5e5;
}

.header-row {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $fontColorBlack;
  overflow-x: scroll;

  .sidebar-btn {
    width: 0;
    height: 0;
    position: absolute;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/hamburger-icon.svg');
  }

  .title-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-container {
      display: flex;
      flex-direction: column;
      position: relative;

      .title {
        font-size: $fontSizeTitle;
      }

      .current-date {
        font-size: $fontSizeMinified;
        color: $fontColorGray;
        margin-top: 4px;
      }

      .reload {
        width: 14px;
        height: 14px;
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 6px;

        img {
          width: 100%;
        }
      }
    }

    .vertical-divider {
      width: 1px;
      height: 54px;
      margin-left: 20px;
      top: 35px;
      background: #c9cde8;
    }

    .closed-value-container {
      margin-left: 20px;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .left-side-actions {
    position: relative;
    display: flex;

    .goal-values {
      position: relative;
      display: flex;
    }
  }

  .right-side-actions {
    margin-left: 10px;
    position: relative;
    display: flex;

    .buttons-divider {
      width: 10px;
    }
  }

  .modal-header {
    width: calc(100% - 80px);
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-title {
      font-size: $fontSizeModalTitle;
    }

    .close-modal-btn {
      width: 24px;
      height: 24px;
      background-size: 14px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../../../assets/icons/close-icon.svg');
      cursor: pointer;
    }
  }

  .modal-error {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: $redWarningColor;
    font-size: $fontSizeMinified;
  }

  .modal-content {
    width: calc(100% - 80px);
    height: 158px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}

.action-btn-absolute-wrapper {
  height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}

.action-btn-container {
  width: 44px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 64px;
  height: 100%;

  .more-actions-btn {
    border: none;
    width: 100% !important;
  }

  .more-actions-btn-active-state {
    border: 1px solid $grayBorderColor;
  }
}

.main-card {
  height: 655px;
  min-height: 655px;
  margin-top: 175px;

  .card-header-row {
    width: calc(100% - 80px);
    height: calc(100% - 47px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-title {
      font-size: $fontSizeTitle;
    }
  }
}

@media (max-width: 1024px) {
  .main-header {
    width: 100%;

    .header-row {
      align-items: center;

      .right-side-actions {
        .action-button {
          width: 46px !important;
          background-size: 24px;
          background-position: center;
          background-repeat: no-repeat;
          text-indent: -9999px;
          cursor: pointer;
        }

        .add-customer-btn {
          background-image: url('../../../../assets/icons/add-icon.svg');
        }

        .import-btn {
          background-image: url('../../../../assets/icons/import-icon-white.svg');
        }
      }

      .sidebar-btn {
        position: relative;
        width: 24px;
        height: 24px;
        margin-right: 15px;
      }

      .action-button {
        margin-top: 0;
      }
    }

    .card-header-row {
      align-items: center;
    }
  }
}

@media (max-height: 900px) {
  .main-card {
    height: calc(100% - 220px);
  }
}

@media (max-width: 420px) {
  .main-card {
    margin-top: 20px;
  }

  .card-footer {
    width: 980px;
  }

  .card-footer:deep(.rows-per-page) {
    margin-left: 340px !important;
  }

  .deals-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    overflow-y: visible !important;
    position: relative;
  }

  .divider-line {
    width: 980px;
  }

  .card-table-header:deep(.table-header-field) {
    margin-left: 60px !important;
  }

  .card-table-header {
    width: calc(100% - 75px) !important;
  }
}

.spacer {
  width: 100%;
  height: 200px;
  min-height: 200px;
}

@media (max-height: 850px) {
  .deals-list {
    overflow-x: scroll;
  }
}
</style>

<style lang="scss">
@import '../../../../assets/styleVars.scss';

.deals-list {
  width: calc(100% - 6px);
  height: calc(100% - 204px);
  overflow-y: scroll;
  position: relative;

  .ghost {
    z-index: 999;
    opacity: 0.9;
    background: $appActionColor !important;
  }

  .chosen-item {
    background: $whiteColor;
  }
}

.more-actions-container {
  position: relative;
}

.error-message {
  width: 100%;
  height: calc(100% - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $redWarningColor;
}
</style>
